export const STARTUP_LAWYER_TESTIMONIALS = [
	{
		text: 'Adrian and his team were very helpful in launching our business on a very sensitive timeline. We could not have done it without their expertise.\nThey were very helpful in answering all of our questions. They also anticipated and addressed problems we may face down the road.\nAdrian and his team were very responsive on the phone and through email.\nI will definitely be using Pandev Law again in the future',
		name: 'Cal Egan',
		stars: 5,
	},
	{
		text: 'Used the services offered by Pandev Law to start a limited liability company and the entire experience was seamless to say the least. Very quick follow-ups from the team and they happily answer any and all questions you ask them with a perfect amount of depth. Would highly recommend them for any of your legal needs!',
		name: 'Karan Narang',
		stars: 5,
	},
	{
		text: 'Adrian was absolutely wonderful to work with. It was so very clear that he cared about my case and he brought a balance of strong legal advice and conversational troubleshooting to fit the specificity of my situation. I waited till after we completed a retainer agreement before writing this, as I noticed that some of the reviews are for the initial consultation. Having worked with him and his team through setting up my business in compliance with legal and immigration laws, I would 10/10 highly recommend his service!',
		name: 'Aniekeme Umoh',
		stars: 5,
	},
	{
		text: 'You will be hard-pressed to find any service that is better than Pandev Law. They are extremely professional - the website is easy to navigate while packed with valuable information, you can easily schedule a call, and everyone is very reassuring, helpful, and responsive. Most importantly, they are able to get things done extremely efficient - it took them just about a week to get my LLC all up and running. I highly recommend them!',
		name: 'Khanh Nguyen',
		stars: 5,
	},
	{
		text: "Pandev Law is an exceptionally professional legal team that go above-and-beyond for their clients. Working personally with Adrian and Michael, I can say with certainty that Pandev Law is a legal team with exceptional knowledge of the industry, as they offer their clients trustworthy legal council and recommendations based on their experience. I'm appreciative of their services and am writing this review to say that if you are considering working with Pandev Law, you are already in good hands.",
		name: 'Jake Crossman',
		stars: 5,
	},
	{
		text: "Just the nicest attorney I've met. Very straightforward and makes every effort to help his client. Would recommend 10/10.",
		name: 'Dinesh Arjun',
		stars: 5,
	},
	{
		text: 'I am extremely satisfied with my experience working with Pandev Law firm. Attorney Michael P. Hayes handled my case; he provided me with outstanding support and made the whole journey very smooth. Throughout the entire process, he handled each step with the highest level of professionalism. I really enjoyed working with Michael from the first meeting till the end of the process. I highly recommend him and the other attorneys at this firm!',
		name: 'George Darouni',
		stars: 5,
	},
	{
		text: 'Adrian did a great job. I was very happy with his work and he was very thorough. I highly recommend him.',
		name: 'Jordan Slotnick',
		stars: 5,
	},
	{
		text: 'Pandev Law helped me and my partner form our new LLC.  They we’re responsive and professional and got the job done!  Wouldn’t hesitate to have them represent me in the future!',
		name: 'Andrew Marchetti',
		stars: 5,
	},
];
