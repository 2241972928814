import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import get from 'lodash.get';
import Layout from '../components/layout';
import Hero from '../components/common/hero';
import heroImg from '../assets/img/startup-lawyer/startup-lawyer.jpg';
import CtaDeck from '../components/common/cta-deck';
import SuccessStoriesDeck from '../components/common/Success-stories-deck';
import PilarNavigation from '../components/pilar/pilar-navigation';
import { STARTUP_LAWYER_TESTIMONIALS } from '../data/startup-lawyer/testimonials';
import AbTestingDeck from '../components/ab-testing/ab-testing-deck';
import CategorySlider from '../components/blog/category-slider';
import Reviewer from '../components/common/reviewer';
import Signature from '../components/common/signature';
import { prependStaticURL } from '../utils';

const headMeta = {
  title: 'Startup Lawyer | Attorney for Business Startups | Pandev Law',
  description:
    "Pandev Law’s startup lawyers represent and advise startups, emerging, and high-growth companies at all stages of their evolution. Let's talk!",
  keywords:
    'investor immigration,investor visa USA,entrepreneur visa USA,US green card by investment',
};
const schema = `[
  {
    "@context": "http://schema.org",
    "@type": "Product",
    "name": "Startup Lawyer | Attorney for Business Startups | Pandev Law",
    "description": "Pandev Law’s startup lawyers represent and advise startups, emerging, and high-growth companies at all stages of their evolution. Let's talk!",
    "url": "https://www.pandevlaw.com/startup-lawyer-for-business-startups/",
    "image": "https://pandevlaw.com/img/startup-lawyer.jpg",
    "brand": "Pandev Law",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "5",
      "bestRating": "5",
      "worstRating": "0",
      "ratingCount": "246"
    },
    "sameAs": [
      "https://en-gb.facebook.com/pandevlaw/"
    ]
  },
  {
  "@context": "https://schema.org",
  "@type": "Article",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://www.pandevlaw.com/startup-lawyer-for-business-startups/"
  },
  "headline": "Startup Lawyer",
  "description": ${JSON.stringify(headMeta.description)},
  "image": ${prependStaticURL(heroImg)},  
  "author": {
    "@type": "Person",
    "name": "Adrian Pandev",
    "url": "https://www.pandevlaw.com/about/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Pandev Law",
    "logo": {
      "@type": "ImageObject",
      "url": "https://www.pandevlaw.com/static/13437dd4aac9a31bf6176b69797f47be/61fd1/logo.webp"
    }
  },
  "datePublished": ""
}]`;

function StartupLawyerServices({ location, data }) {
  const { successStoriesPosts } = data;
  const category = get(data, 'category', null);

  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema}>
      <Hero
        title="Startup Lawyer"
        img={heroImg}
        imgAlt="Business startup lawyers for business startups">
        <p className="text-lead mb-5 mb-md-6">
          Pandev Law's startup lawyers represent and advise startups, emerging, and
          high-growth companies at all stages of their evolution. Our startup attorneys
          represent founders, angel investors, and venture capital firms in a multitude of
          startup related transactions. Our experience advising both founders and
          investors provides us with a comprehensive understanding of the legal issues
          faced by startups at all stages of their lifecycle. If you're wondering how to
          find a startup lawyer, our firm exemplifies the pinnacle of legal expertise and
          dedication to the startup ecosystem. Due to our extensive experience with
          international clients we are considered the leading startup law firm for
          handling the unique multidisciplinary legal challenges faced by foreign
          startups, their founders, and investors. If you are wondering how to find a
          startup lawyer, look no further than our firm. Our business startup attorneys
          provide a full-spectrum of startup law services from formation to capital
          raising and intellectual property protection. Launching a new company is a
          complex endeavor, but you can rely on our team of highly-regarded startup
          lawyers to serve as your foundation through every step of the process. Pandev
          Law's startup attorneys have guided a long list of successful startup clients
          through critical milestones, including:
        </p>
      </Hero>

      <AbTestingDeck
        position="top"
        title="Startup Lawyer Client Reviews"
        testimonials={STARTUP_LAWYER_TESTIMONIALS}
      />

      <div className="section pt-5 pb-5 pt-md-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/startup-lawyer/startup-formation-attorney.jpg"
                alt="Startup lawyers helping with startup formation"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <Reviewer />
              <h2 className="text-headline mb-5 mb-md-6">Startup Formation</h2>
              <p className="text-body mb-6">
                Properly organizing your startup is the first critical step in the startup
                process. Our startup lawyer services include the formation of your new
                business venture &mdash; from deciding on a specific entity type, to
                advising you on complex cross-border formation issues. We firmly believe
                an effective business startup attorney must have the ability to address
                all legal needs of the business in order to properly serve the needs and
                concerns of the client. As a fundamental aspect of the startup process, we
                understand that proper startup organization provides a long list of
                benefits to founders and investors, such as facilitating raising capital,
                gaining acceptance to leading accelerators, and entering important
                agreements with industry leaders.
                <br />
                <br />
                Often, startup business lawyer fees are unmanageable for an emerging
                business. This is why we tailor the fees for our startup lawyer services
                to be compatible with the budgetary constraints most startups experience.
              </p>
              <div className="accordion" id="corporateLaw">
                <div className="mb-5">
                  <div id="corporateLawHeadingOne">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseOne"
                      aria-expanded="true"
                      aria-controls="corporateLawCollapseOne">
                      <strong>Startup Company Formation</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseOne"
                    className="collapse show"
                    aria-labelledby="corporateLawHeadingOne"
                    data-parent="#corporateLaw">
                    <p className="text-body my-4">
                      Selecting the entity type for the new business is one of the first
                      of many critical decisions every startup founder must make. As such,
                      weighing out the pros and cons of each potential entity type your
                      startup could adopt is vital in order to ensure the most
                      advantageous selection is made. The plethora of options can present
                      a daunting task in this regard, but our startup lawyer services can
                      help you decide which structure is the best fit for the specific
                      needs of your startup. The number of founders, the type of products
                      and services offered by your startup, whether you wish to raise
                      venture capital, and whether any of the founders are based outside
                      the United States are just some of the multitude of factors that
                      must be considered when deciding on the right entity type for your
                      company. Pandev Law's startup attorneys have helped our clients form
                      companies spanning a wide array of entity types including, but not
                      limited to:
                    </p>
                    <ul className="list-bullets">
                      <li>Corporation - including C-Corp and S-Corp</li>
                      <li>Limited Liability Company (LLC)</li>
                      <li>Partnership - including GP, LP, LLP, LLLP</li>
                      <li>Benefit Corporation </li>
                      <li>Nonprofit Corporation</li>
                    </ul>
                    <p className="my-4">
                      Our startup law services include advising on whether to form your
                      company in the founders' home state or a more business-friendly
                      jurisdiction, such as Delaware. After deciding on the best structure
                      for your startup, our startup attorneys file all required documents
                      with the appropriate government entities. During the formation
                      process, our lawyers for business startups draft all necessary
                      organizational documents, including company bylaws, operating
                      agreements, stockholder agreements, director and shareholder
                      resolutions, and various other corporate governance documents. If
                      you are navigating how to find the best startup lawyer to form your
                      company, our firm is here to advise. We help decide on the proper
                      entity type, most advantageous jurisdiction, and best internal
                      structure for your startup. Our business startup lawyers then make
                      all necessary filings with the State Secretary's Office and IRS as
                      well as draft all relevant formation documents. We provide you with
                      a turn-key company formation service so you can focus on your
                      startup business.
                      <br />
                      <br />
                      While we rely on our extensive experience to give your startup the
                      successful beginning it deserves, we understand that your ability to
                      budget for startup business lawyer fees might be limited. Taking
                      that into consideration, as part of our startup lawyer services, we
                      offer a flat fee company formation package that provides your
                      startup business with the essentials it needs at a reasonable price.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="corporateLawHeadingTwo">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseTwo"
                      aria-expanded="false"
                      aria-controls="corporateLawCollapseTwo">
                      <strong>Co-Founder Equity Split</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseTwo"
                    className="collapse"
                    aria-labelledby="corporateLawHeadingTwo"
                    data-parent="#corporateLaw">
                    <p className="my-4">
                      Allocating equity and decision-making authority among co-founders
                      from the outset is vital to ensuring your startup does not get
                      exposed to crippling shareholder disputes. Fortunately, our startup
                      lawyer services will provide you with the necessary advice to help
                      you choose the best business equity structure, which can also
                      massively impact the growth of your startup. Moreover, our talented
                      team of lawyers for starting a business is able to draft all of the
                      necessary documents to facilitate maximum growth of your new
                      company:
                    </p>
                    <ul className="list-bullets">
                      <li>Stock purchase agreements</li>
                      <li>
                        Founder shares - ensure all founder shares are issued properly
                      </li>
                      <li>
                        Vesting schedules - design, implement, advise on vesting schedules
                      </li>
                      <li>Cap tables - establish and manage cap tables</li>
                      <li>Shareholder and Founder agreements</li>
                    </ul>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="corporateLawHeadingThree">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseThree"
                      aria-expanded="false"
                      aria-controls="corporateLawCollapseThree">
                      <strong>Stock Option Pools & Equity Incentive Plans</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseThree"
                    className="collapse"
                    aria-labelledby="corporateLawHeadingThree"
                    data-parent="#corporateLaw">
                    <div className="my-4">
                      Our startup lawyers understand that assembling a talented team is
                      absolutely critical for any company's success. To incentivize
                      employees, startups often create stock option pools or equity
                      incentive plans that grant employees equity in the company when
                      certain milestones are reached. Our startup attorneys have an
                      in-depth understanding of the equity and stock option award process.
                      We have developed and implemented employee incentive plans for
                      startups of various sizes and in different industries. We understand
                      the nuances of different stock options and focus on devising an
                      incentive structure best tailored to your startup's needs.
                    </div>
                  </div>
                </div>
                <div>
                  <div id="corporateLawHeadingFour">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseFour"
                      aria-expanded="false"
                      aria-controls="corporateLawCollapseFour">
                      <strong>Intellectual Property</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseFour"
                    className="collapse"
                    aria-labelledby="corporateLawHeadingFour"
                    data-parent="#corporateLaw">
                    <p className="my-4">
                      We believe every founder must have adequate intellectual property
                      protection to ensure their valuable ideas, branding, and designs are
                      properly guarded. Our startup law firm offers comprehensive
                      solutions, including Non-Disclosure Agreements, Confidentiality
                      Agreements, and Intellectual Property (IP) Assignment Agreements to
                      give all founders and equity holders of your startup peace of mind
                      regarding the protection of its valuable IP assets. Our startup
                      attorneys also ensure that all privacy policies included in these
                      agreements are aligned with all relevant rules and regulations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/startup-lawyer/startup-fundraising.jpg"
                alt="Startup attorneys helping with startup fundraising"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">Startup Fundraising</h2>
              <p className="text-body mb-6">
                To scale from a promising startup to a prospering emerging growth company
                often requires outside financing. Our startup law firm has extensive
                experience with the startup fundraising process. Our startup lawyers
                listen to your goals and concerns and develop capital raising strategies
                tailored to your startup's needs. We have represented startups and
                emerging growth companies in financing transactions at various stages of
                their growth cycle. Our startup attorney fundraising services include:
              </p>
              <div className="accordion" id="internationalBusinessLaw">
                <div className="mb-5">
                  <div id="internationalBusinessLawHeadingZero">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#internationalBusinessLawCollapseZero"
                      aria-expanded="true"
                      aria-controls="internationalBusinessLawCollapseZero">
                      <strong>SAFE Agreements</strong>
                    </h2>
                  </div>
                  <div
                    id="internationalBusinessLawCollapseZero"
                    className="collapse show"
                    aria-labelledby="internationalBusinessLawHeadingZero"
                    data-parent="#internationalBusinessLaw">
                    <p className="text-body my-4">
                      The Simple Agreement for Future Equity (SAFE) is a relatively new
                      legal instrument which has simplified the fundraising process. SAFE
                      agreements are the most common seed fundraising vehicle, regularly
                      used by our lawyer for business startups. SAFEs offer key advantages
                      over other seed fund raising vehicles by eliminating accrued
                      interest, set maturity dates, and all of the complexities of
                      maturity events. This not only lowers legal costs, but also reduces
                      the negotiation time &mdash; both benefits which are highly valuable
                      to startup companies.
                      <br />
                      <br />
                      Pandev Law's startup attorneys understand the intricacies of SAFE
                      agreements. We have the requisite expertise to advise on the correct
                      use of pre-money vs post-money SAFEs, MFN provisions, maximum
                      valuation caps, side letters, and pro rata rights for your startup.
                      Although SAFEs are generally drafted by the company's business
                      startup attorneys, often seed investors, such as angel investors,
                      venture capital funds, or family and friends, may have specific
                      requirements they wish to include in the SAFE. Our lawyers have
                      represented numerous startups and investors during SAFE fundraising.
                      We understand the critical terms for both investors and startups in
                      SAFE agreements. We rely on our knowledge and experience to
                      negotiate the terms of your SAFE agreements to ensure a successful
                      fundraising round for your startup.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="internationalBusinessLawHeadingOne">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#internationalBusinessLawCollapseOne"
                      aria-expanded="false"
                      aria-controls="internationalBusinessLawCollapseOne">
                      <strong>Convertible Notes</strong>
                    </h2>
                  </div>
                  <div
                    id="internationalBusinessLawCollapseOne"
                    className="collapse"
                    aria-labelledby="internationalBusinessLawHeadingOne"
                    data-parent="#internationalBusinessLaw">
                    <p className="text-body my-4">
                      Our startup lawyer services include advising on convertible notes
                      and determining whether they are a viable option for your startup.
                      Convertible notes are debt instruments that convert into equity when
                      a triggering event, most often a future equity investment, occurs.
                      Our startup attorneys understand both the benefits and risks
                      associated with convertible notes. If the note does not convert to
                      equity in the company, a startup may be forced to pay the debt with
                      accrued interest or enter complex negotiations to extend the
                      maturity date. Our startup lawyers understand the nuances of
                      convertible notes and use various provisions, such as automatic
                      conversions, to protect our startup clients from unfavorable
                      outcomes. As part of our legal services for startups, we draft,
                      review, and negotiate the terms of convertible notes with a focus on
                      your company's short and long term goals.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="internationalBusinessLawHeadingTwo">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#internationalBusinessLawCollapseTwo"
                      aria-expanded="false"
                      aria-controls="internationalBusinessLawCollapseTwo">
                      <strong>Series Seed Preferred</strong>
                    </h2>
                  </div>
                  <div
                    id="internationalBusinessLawCollapseTwo"
                    className="collapse"
                    aria-labelledby="internationalBusinessLawHeadingTwo"
                    data-parent="#internationalBusinessLaw">
                    <p className="text-body my-4">
                      Seed investors sometimes prefer to receive a defined amount of
                      equity for their capital investment. In such cases, the investor and
                      startup must reach an agreement regarding the valuation of the
                      company. Once a valuation is agreed upon, the parties enter a series
                      seed preferred stock investment agreement through which the investor
                      receives a specific amount of shares in return for its capital
                      investment. Our business startup attorneys have years of experience
                      with series seed preferred stock transactions, having represented
                      startups in deals with some of the most prominent venture capital
                      firms in the United States. Our series seed preferred startup
                      attorney services include:
                    </p>
                    <ul className="list-bullets">
                      <li>Term sheet - draft and negotiate transaction term sheet</li>
                      <li>Due diligence - review and clean up company documents </li>
                      <li>
                        Restated Certificate of Incorporation - draft and review
                        certificates
                      </li>
                      <li>
                        Corporate resolutions - draft stockholder and director resolutions
                      </li>
                      <li>
                        Indemnification agreements - review indemnification provisions
                      </li>
                      <li>Securities filings - SEC and state blue sky filings</li>
                      <li>Cap table - manage company cap table</li>
                      <li>
                        Negotiate and provide strategic advice throughout transaction
                      </li>
                    </ul>
                    <p className="my-4">
                      As a result of our extensive experience, our clients can be
                      confident in our business setup attorneys' ability to provide
                      comprehensive advice through every stage of their series seed
                      preferred round.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="internationalBusinessLawHeadingThree">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#internationalBusinessLawCollapseThree"
                      aria-expanded="false"
                      aria-controls="internationalBusinessLawCollapseThree">
                      <strong>Regulation Crowdfunding</strong>
                    </h2>
                  </div>
                  <div
                    id="internationalBusinessLawCollapseThree"
                    className="collapse"
                    aria-labelledby="internationalBusinessLawHeadingThree"
                    data-parent="#internationalBusinessLaw">
                    <p className="text-body my-4">
                      Regulation Crowdfunding is a newer vehicle for startup fundraising.
                      Regulation crowdfunding provides startups an opportunity to leverage
                      their online following to raise funds. It allows companies to raise
                      capital through online intermediaries or platforms. As any lawyer
                      for business startups can attest, the rules governing crowdfunding
                      are still evolving. Our attorneys have experience with crowdfunding
                      and can help navigate you through the process. Our startup attorney
                      services include analyzing whether your company is a good candidate
                      for crowdfunding and guiding you through the legal steps and
                      disclosures involved in the crowdfunding process.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="internationalBusinessLawHeadingFour">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#internationalBusinessLawCollapseFour"
                      aria-expanded="false"
                      aria-controls="internationalBusinessLawCollapseFour">
                      <strong>Series A</strong>
                    </h2>
                  </div>
                  <div
                    id="internationalBusinessLawCollapseFour"
                    className="collapse"
                    aria-labelledby="internationalBusinessLawHeadingFour"
                    data-parent="#internationalBusinessLaw">
                    <p className="text-body my-4">
                      Series A financing is an essential part of the growth path of every
                      successful startup. Unlike seed funding, a startup going through
                      Series A fundraising has usually reached a defined product-market
                      fit and customer base. Our attorneys have a thorough understanding
                      of the Series A fundraising process. We help founders prepare the
                      company for a Series A round and negotiate the terms of the deal.
                      Pandev Law's Series A startup lawyer services include determining
                      investor rights, guiding founders through due diligence, and
                      drafting all necessary agreements and resolutions to conclude the
                      transaction. We work as your trusted advisor, helping you navigate
                      the Series A process so that you can continue to focus on growing
                      your company.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="internationalBusinessLawHeadingFive">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#internationalBusinessLawCollapseFive"
                      aria-expanded="false"
                      aria-controls="internationalBusinessLawCollapseFive">
                      <strong>Series B, C, and Beyond</strong>
                    </h2>
                  </div>
                  <div
                    id="internationalBusinessLawCollapseFive"
                    className="collapse"
                    aria-labelledby="internationalBusinessLawHeadingFive"
                    data-parent="#internationalBusinessLaw">
                    <p className="text-body my-4">
                      Pandev Law is here to help your company as it continues to scale.
                      Our startup attorneys have the ability and necessary know-how to
                      guide your company through series B, C, and subsequent fundraising
                      rounds. We are considered leading lawyers for starting a business
                      because we approach fundraising with a holistic strategy focusing on
                      your exit goals as much as your company's current needs. This allows
                      us to comprehensively and effectively advise you on the best
                      practices through your Series B, C and subsequent rounds of capital
                      raising.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/startup-lawyer/startup-agreements-contracts.jpg"
                alt="Lawyer for business startups helping with startup agreements and contracts"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">
                Startup Agreements &amp; Contracts
              </h2>
              <p className="text-body mb-5">
                Pandev Law has earned a reputation as a leading startup law firm when it
                comes to assisting emerging companies on their path to growth. Our startup
                lawyer services focus on providing comprehensive guidance for your
                startup, relying on our broad range of expertise derived from working with
                numerous startups from around the world. Our legal services for startups
                include drafting, reviewing, negotiating, and advising on a wide range of
                agreements and contracts. Our lawyers are intimately familiar with the
                numerous transactions faced by startups. We effectively advise clients
                throughout all phases of your company's growth process.
              </p>
              <div className="accordion" id="InvestorVisaUSA">
                <div className="mb-5">
                  <div id="InvestorVisaUSAHeadingZero">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#InvestorVisaUSACollapseZero"
                      aria-expanded="true"
                      aria-controls="InvestorVisaUSACollapseZero">
                      <strong>General Business Agreements</strong>
                    </h2>
                  </div>
                  <div
                    id="InvestorVisaUSACollapseZero"
                    className="collapse show"
                    aria-labelledby="InvestorVisaUSAHeadingZero"
                    data-parent="#InvestorVisaUSA">
                    <p className="text-body my-4">
                      As part of our startup legal services, our team will assist you with
                      any negotiations to successfully close a deal with the most
                      favorable terms possible. We structure and draft all of your
                      contracts and agreements to ensure the protection of your products,
                      ideas, and business as a whole. Our business startup attorneys have
                      the ability to draft and review a comprehensive array of contracts
                      related to negotiations and agreements with clients, partners,
                      vendors, and suppliers. In doing so, we will help ensure you are
                      maximizing your profit and growth potential.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="InvestorVisaUSAHeadingOne">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#InvestorVisaUSACollapseOne"
                      aria-expanded="false"
                      aria-controls="InvestorVisaUSACollapseOne">
                      <strong>Technology Agreements</strong>
                    </h2>
                  </div>
                  <div
                    id="InvestorVisaUSACollapseOne"
                    className="collapse"
                    aria-labelledby="InvestorVisaUSAHeadingOne"
                    data-parent="#InvestorVisaUSA">
                    <p className="text-body my-4">
                      In today's digital world, a significant portion of newly formed
                      business ventures focus on new technologies and their applications.
                      As a founder or investor in a technology startup, you are
                      undoubtedly aware of the inherent complexities associated with this
                      rapidly evolving sector. It is critical that any lawyer for business
                      startups be highly knowledgeable and experienced with various
                      technology agreements. Pandev Law's business startup attorneys have
                      the knowledge and expertise to draft and negotiate the best terms of
                      a comprehensive array of tech-related agreements, including SaaS,
                      development services, and manufacturing agreements. Our startup law
                      firm has extensive experience with website terms and conditions,
                      privacy policies, DMCA policies, and EULAs which can protect your
                      startup from unwanted legal challenges.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="InvestorVisaUSAHeadingTwo">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#InvestorVisaUSACollapseTwo"
                      aria-expanded="true"
                      aria-controls="InvestorVisaUSACollapseTwo">
                      <strong>Employment Agreements</strong>
                    </h2>
                  </div>
                  <div
                    id="InvestorVisaUSACollapseTwo"
                    className="collapse"
                    aria-labelledby="InvestorVisaUSAHeadingTwo"
                    data-parent="#InvestorVisaUSA">
                    <p className="text-body my-4">
                      Every startup company relies on the efforts of its dedicated team.
                      This is why well-defined employee agreements represent the core of a
                      healthy emerging company. Our startup lawyer services comprise
                      employment and independent contractor agreements. Pandev Law's
                      startup business attorneys assist clients with properly classifying
                      staff as employees or independent contractors, and drafting
                      employment agreements and independent contractor agreements. We help
                      founders navigate their relationships with employees, contractors,
                      and advisors. Our startup lawyer services cover the full spectrum of
                      your employment agreement needs &mdash; from negotiation to advising
                      on best practices for your startup company.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="InvestorVisaUSAHeadingTwo">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#InvestorVisaUSACollapseTwo"
                      aria-expanded="true"
                      aria-controls="InvestorVisaUSACollapseTwo">
                      <strong>Intellectual Property Agreements</strong>
                    </h2>
                  </div>
                  <div
                    id="InvestorVisaUSACollapseTwo"
                    className="collapse"
                    aria-labelledby="InvestorVisaUSAHeadingTwo"
                    data-parent="#InvestorVisaUSA">
                    <p className="text-body mt-4 mb-5">
                      The business startup attorneys at Pandev Law recognize the essential
                      need for startup business founders to preserve and protect their
                      valuable intellectual property rights. The attorneys from Pandev
                      Law's intellectual property practice have an impressive depth of
                      experience with various IP transactions. Our startup law firm
                      assists clients with:
                    </p>
                    <ul className="list-bullets">
                      <li>Confidentiality and Proprietary Rights Agreements</li>
                      <li>Licensing Agreements</li>
                      <li>Non-Disclosure Agreements</li>
                      <li>Intellectual Property Assignment Agreements</li>
                      <li>Invention Assignment Agreements</li>
                      <li>Consulting Agreements</li>
                      <li>Development Agreements</li>
                    </ul>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="InvestorVisaUSAHeadingThree">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#InvestorVisaUSACollapseThree"
                      aria-expanded="true"
                      aria-controls="InvestorVisaUSACollapseThree">
                      <strong>Service Agreements</strong>
                    </h2>
                  </div>
                  <div
                    id="InvestorVisaUSACollapseThree"
                    className="collapse"
                    aria-labelledby="InvestorVisaUSAHeadingThree"
                    data-parent="#InvestorVisaUSA">
                    <p className="text-body my-5">
                      Service Agreements are essential for ensuring that all parties to a
                      transaction understand their rights and obligations under an
                      agreement. They minimize the risk of unmet expectations for all
                      parties in a transaction. Pandev Law's business startup attorneys
                      understand the critical importance of service agreements for the
                      success of an emerging company. We have the necessary know-how to
                      draft and negotiate effective service agreements to help scale your
                      business.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="InvestorVisaUSAHeadingFour">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#InvestorVisaUSACollapseFour"
                      aria-expanded="true"
                      aria-controls="InvestorVisaUSACollapseFour">
                      <strong>Outsourcing Agreements</strong>
                    </h2>
                  </div>
                  <div
                    id="InvestorVisaUSACollapseFour"
                    className="collapse"
                    aria-labelledby="InvestorVisaUSAHeadingFour"
                    data-parent="#InvestorVisaUSA">
                    <p className="text-body my-4">
                      An outsourcing agreement is a contract that defines the rights and
                      responsibilities of the parties involved in an outsourcing project,
                      typically a vendor and a buyer. Outsourcing agreements are becoming
                      more important as US startups outsource much of their production and
                      services to foreign entities. A properly drafted outsourcing
                      agreement can help define work expectations, quality parameters,
                      timelines, and pricing structures, which are all integral parts of
                      the production process. We are considered leading lawyers to set up
                      a business because we have experience drafting and negotiating
                      critical startup agreements, including outsourcing agreements, which
                      help streamline production processes and avoid unnecessary setbacks.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/startup-lawyer/business-lawyer-for-startup-helping-with-startup-corporate-governance.jpg"
                alt="Business lawyer for startup helping with startup corporate governance"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">Startup Corporate Governance</h2>
              <p className="text-body mb-6">
                Establishing clearly defined corporate governance policies is critical for
                an emerging company's growth trajectory. Pandev Law is considered one of
                the top startup law firms because our attorneys understand the intricacies
                of the board of directors and board of advisors selection process. We have
                represented a long list of startups regarding corporate governance and
                shareholder issues. Our startup attorneys have experience documenting the
                election of board of directors and advisors. We regularly draft
                resolutions to memorialize corporate decisions, such as the formation of
                employee stock option pools and equity incentive plans. For those
                pondering where to find a startup lawyer for ensure proper startup
                corporate governance and compliance, our firm is here to help. As part of
                our due diligence for capital raises, mergers, or acquisitions, we review
                and clean up startup corporate governance documents and counsel founders
                on various corporate governance decisions. Our startup law team
                understands the goals of founders, their employees, and investors. We
                provide corporate governance advice that focuses on the continued
                scalability of your startup enterprise.
              </p>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5  mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/startup-lawyer/international-founder-startup-planning.jpg"
                alt="Startup lawyers helping with international founder startup planning"
              />
            </div>
            <div className="col-lg-6 offset-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">
                International Founder Startup Planning
              </h2>
              <p className="text-body mb-6">
                Pandev Law's international focus makes us the premier startup law firm for
                foreign founders. Our startup lawyers have in-depth knowledge and years of
                experience structuring foreign-owned startups and negotiating cross-border
                transactions. We combine our corporate, tax, immigration, and regulatory
                practices to provide holistic legal services for startups. Foreign-owned
                startups often require special considerations driven by tax, immigration,
                and foreign legal constraints. Our startup attorneys are highly
                experienced in helping foreign startups enter the US market and raising
                funds from US-based investors. If you are a foreign founder looking for a
                lawyer for a business startup, our team of attorneys has the necessary
                know-how and international transaction experience to help you scale your
                US company.
              </p>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/startup-lawyer/startup-attorneys-helping-with-startup-investor-representation.jpg"
                alt="Startup attorneys helping with startup investor representation"
              />
            </div>
            <div className="col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">
                Startup Investor Representation
              </h2>
              <p className="text-body mb-6">
                As part of our startup lawyer services we represent individual and
                institutional investors looking for opportunities in the US startup
                ecosystem. Pandev Law's team of highly knowledgeable startup attorneys
                have successfully represented individuals, angel Investors, and venture
                capital firms in startup and early stage company investments. Our broad
                experience working with startups and their founders helps us effectively
                counsel investors on best practices. We are known to structure deals that
                protect investor capital while promoting startup company growth. Given our
                long list of foreign clients, we are widely considered a leading law firm
                for cross-border investment in US startups. Our attorneys understand the
                corporate governance of US startup companies and the intricacies of
                cross-border investments into the United States. We advise and represent
                foreign investors, both individual and institutional, through each step of
                the US startup investment process, from drafting initial term sheets to
                due diligence, negotiation, and through final closing of the deal.
              </p>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <h2 className="text-headline mb-5 mb-md-6">Startup Exit Planning</h2>
            <p className="text-body mb-6">
              Pandev Law is regarded as a top law firm for startups because our attorneys
              prioritize learning our clients' goals and motivations. We strive to
              understand what drives you so that we can help steer you towards a
              successful exit from your startup venture. Many startup founders are so
              consumed by the day-to-day management of the business that they forget their
              long-term objectives. Our startup lawyers help you navigate the everyday
              while focusing on your future goals. Regardless of your vision for your
              company's future, our attorneys can develop a comprehensive exit strategy
              and help guide you towards it. We understand that business plans are
              constantly evolving, so we help adapt your legal strategy to the changing
              circumstances of your business. When the time comes to make your exit, our
              seasoned startup lawyers will be there to structure and negotiate the exit
              transaction. Our goal is to ensure that you are rewarded for all of the hard
              work and dedication you have put into your business. Our highly experienced
              attorneys will not only plan and re-assess your exit strategy, but will also
              utilize our drafting and negotiation skills to ensure you receive the most
              favorable terms when the time comes for you to exit your startup venture.
            </p>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="mb-4 mb-lg-0">
              <h2 className="text-headline mb-5 mb-md-6">
                <a
                  href="/general-counsel-services/"
                  target="_blank"
                  rel="noopener noreferrer">
                  Startup General Counsel Services
                </a>
              </h2>
              <p className="text-body mb-6">
                Pandev Law serves as outside general counsel to numerous startups
                nationwide. We understand that as the founder of an early stage company
                you will have many legal questions and require representation in various
                legal transactions. We tailor our{' '}
                <a
                  href="/general-counsel-services/"
                  target="_blank"
                  rel="noopener noreferrer">
                  startup general counsel services
                </a>{' '}
                to your needs, objectives, and budget. We strive to become an integral
                component of your startup &mdash; a trusted legal advisor to whom you can
                turn during every important company decision and milestone. As your
                general counsel, our attorneys will obtain a holistic understanding of
                your business, which allows us to preempt legal issues and identify
                opportunities for your company. Our startup lawyers are focused on helping
                your company scale while protecting you from legal challenges. As part of
                general counsel services, you will have on-going access to comprehensive
                legal counsel with deep-rooted experience in startup matters. No matter
                the complexity of your business, our attorneys are here to guide and
                advise you through each step of your startup journey.
              </p>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/startup-lawyer/startup-immigration-services.jpg"
                alt="Business lawyer for startup helping with startup immigration services"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">Startup Immigration Services</h2>
              <p className="text-body mb-5">
                In addition to being a top startup law firm, Pandev Law is regarded as a
                leader in immigration law. This experience serves as the foundation on
                which our business startup attorneys consult founders who are foreign
                nationals. Our startup and immigration practices work together to provide
                comprehensive legal solutions for our foreign founder clients. Our
                in-depth knowledge and experience in both startup and immigration law
                differentiates us from our competitors and firmly establishes Pandev Law
                among the top startup law firms for foreign founders.
                <br />
                <br />
                Foreign founders have to make sure their immigration status permits them
                to work for their startup. Because each visa category has specific
                requirements for both the petitioning company and the beneficiary
                employee, our startup attorneys perform a careful analysis of the
                company’s business, the founders’ goals, and the founders’ roles within
                the company. Our attorneys combine their immigration law and startup
                experience to deliver comprehensive immigration solutions that allow
                foreign founders to grow their US company. Some of the visa types that our
                attorneys have helped startup founders obtain include:
              </p>
              <ul className="list-bullets">
                <li className="mb-4">
                  <a href="/e-2-visa/" target="_blank" rel="noopener noreferrer">
                    <b>E-2 Visa:</b>
                  </a>{' '}
                  Available to foreign nationals who are citizens of a country that has a
                  treaty with the United States. The{' '}
                  <a href="/e-2-visa/" target="_blank" rel="noopener noreferrer">
                    E-2 visa
                  </a>{' '}
                  is widely used by foreign founders making a substantial investment in
                  their US startup.
                </li>
                <li className="mb-4">
                  <b>O-1 Visa:</b> Available to foreign founders who possess extraordinary
                  ability in the sciences, arts, education, business or athletics. The O-1
                  visa is frequently used by founders of technology startups with STEM
                  backgrounds.{' '}
                </li>
                <li className="mb-4">
                  <a href="/l-1-visa/" target="_blank" rel="noopener noreferrer">
                    <b>L-1 Visa:</b>
                  </a>{' '}
                  Available to founders who worked for a subsidiary, parent, affiliate or
                  branch office of the US company outside of the United States for at
                  least one year out of the last three years.{' '}
                </li>
                <li className="mb-4">
                  <a
                    href="/blog/what-is-an-h-1b-visa/"
                    target="_blank"
                    rel="noopener noreferrer">
                    <b>H-1B Visa:</b>
                  </a>{' '}
                  Available to founders who hold a bachelor's degree or higher in a
                  specific field related to the work they are performing at the startup.{' '}
                </li>
                <li className="mb-4">
                  <a
                    href="/blog/employment-based-immigration-f1-students/"
                    target="_blank"
                    rel="noopener noreferrer">
                    <b>F-1 OPT Self-Employment:</b>
                  </a>{' '}
                  Available to foreign founders who are recent university graduates with
                  approved post-completion OPT and meet certain requirements.
                </li>
              </ul>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/startup-lawyer/startup-intellectual-property-services.jpeg"
                alt="Business startup lawyers helping with startup intellectual property services"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">
                Startup Intellectual Property Services
              </h2>
              <p className="text-body mb-5">
                Pandev Law is well equipped to advise our clients in obtaining protection
                for their company's intellectual property assets. Our team of business
                startup attorneys has extensive experience with{' '}
                <a
                  href="/intellectual-property-legal-services/"
                  target="_blank"
                  rel="noopener noreferrer">
                  intellectual property transactions
                </a>{' '}
                IP Protection for Startups. We specialize in a number of IP-related legal
                services for startups, including:
              </p>
              <ul className="list-bullets">
                <li className="mb-4">
                  <b>IP Transactions &amp; Licensing:</b> Our startup lawyer services
                  include representing clients in intellectual property transactions. We
                  represent startups in licensing, joint ventures, development, sales, and
                  purchases of intellectual property. Pandev Law is known for its
                  international capabilities in representing technology companies, making
                  us a leader in cross-border IP transactions.
                </li>
                <li className="mb-4">
                  <b>Internet &amp; E-Commerce:</b> Our business setup attorneys can
                  advise on best practices to help navigate your company's online
                  presence. The digital footprint of your startup might include a website,
                  mobile app, podcast, and/or social media accounts. Pandev Law's startup
                  lawyer services provide legal strategies that promote growth, while
                  minimizing legal liability.
                </li>
                <li className="mb-4">
                  <b>Website Agreements:</b> Our attorneys advise startups on their online
                  presence. We draft and review website agreements, such as terms of
                  service, privacy policies, and license agreements. Founders often forget
                  that as the owner of your company's website, you are in a legal
                  relationship with every visitor to your site. As leading lawyers for
                  business startups, we provide a full set of website agreements tailored
                  to your startup's needs.
                </li>
                <li className="mb-4">
                  <b>Advertising &amp; Marketing:</b> A strong social media presence,
                  email marketing campaign, or online ad campaign can be highly beneficial
                  for a startup company. However, the ever changing rules governing online
                  advertising and the terms and conditions of social media platforms can
                  expose your new business to a myriad of risks. The startup attorneys at
                  Pandev Law understand the nuanced rules governing online advertising,
                  marketing, and social media platform compliance.
                </li>
                <li className="mb-4">
                  <b>Trademark &amp; Copyright Prosecution:</b> Our startup lawyer
                  services include trademark and copyright prosecution to ensure your
                  brand and original works are protected. As your startup grows, so does
                  the value of your brand. Our attorneys help you protect and monetize
                  your brand and original works.
                </li>
                <li className="mb-4">
                  <b>Cybersecurity, Privacy, and Data Protection:</b> Cybersecurity and
                  data protection are an important risk management concern for startups.
                  Cyber attacks and data breaches are increasing worldwide. Companies are
                  under increased regulatory pressure to safeguard the confidential and
                  personal information they are entrusted with. Our startup lawyers work
                  together with engineering and technical teams to develop policies that
                  protect our clients and their companies from data privacy and protection
                  related legal challenges.
                </li>
              </ul>
            </div>
          </div>
          {/* TODO: Remove d-none when articles become available */}
          <hr className="d-none separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="d-none row">
            <h2 className="text-headline mb-5 mb-md-6">
              Startup Lawyer Additional Resources
            </h2>
            <p className="text-body mb-6">
              To deepen your understanding of our firm's startup law offerings or to learn
              more about the startup law landscape, our website hosts additional
              resources. Whether you're in the early stages of founding your company or
              seeking to navigate complex legal landscapes, insights from articles like
              Startup Laws can provide you with foundational knowledge. For those wishing
              to connect with a startup lawyer in their area, Startup Attorney Near Me
              offers guidance on locating the right legal partner. To ensure you are
              well-prepared for your initial consultation with our firm, Questions to Ask
              Startup Lawyer equips you with the crucial queries that can help develop
              your legal strategy. Additionally, understanding the financial commitment
              involved in any attorney-client relationship is essential. How Much Does a
              Startup Lawyer Cost offers transparency on the investment needed for
              comprehensive legal support. Together, these resources form a comprehensive
              guide to assist you through the intricacies of startup law and our firm's
              specialized services.
            </p>
          </div>
          <Signature />
        </div>
      </div>
      {get(category, 'posts.nodes.length', 0) > 0 && (
        <>
          <hr className="my-8" />
          <CategorySlider index={2} category={category} />
        </>
      )}
      <AbTestingDeck
        title="Startup Lawyer Client Reviews"
        testimonials={STARTUP_LAWYER_TESTIMONIALS}
      />
      <PilarNavigation />
      <CtaDeck />
      <SuccessStoriesDeck posts={successStoriesPosts} />
    </Layout>
  );
}

export const query = graphql`
  query StartupLawyerQuery {
    category: wpCategory(slug: { eq: "startup-law" }) {
      ...BlogLandingHeroPost
    }
    successStoriesPosts: allWpPost(
      sort: { date: DESC }
      filter: {
        slug: {
          in: [
            "series-seed-financing-for-1-25mm-256"
            "accelerator-participation-agreement-260"
            "stock-option-pool-organized-for-startup-236"
            "seed-round-corporate-work-262"
            "securing-key-advisor-through-post-money-safe-254"
            "startup-initial-organization-246"
            "pre-seed-corporate-round-work-242"
            "e-verify-registration-approved-261"
            "initial-corporate-work-228"
            "business-initial-organization-192"
          ]
        }
      }
    ) {
      edges {
        node {
          id: databaseId
          title
          date
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              altText
            }
          }
          slug
          excerpt
        }
      }
    }
  }
`;

export default StartupLawyerServices;
